export const initialUser = () => ({
  order_id: "",
  no_order: "",
  name: "",
  zip_code: "",
  vat: "",
  regime: "",
  email: "",
  payment_ids: [],
});
