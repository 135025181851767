import React from 'react';
import { allowOnlyNumbers } from '../../utils/globals';

const NumericInput = ({ value = '', setValue, onChange, money, ...props }) => {
  
  const formatMoney = (value) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(value);
  };

  const handleChange = (e) => {
    const filteredValue = allowOnlyNumbers(e.target.value);
    onChange(filteredValue);
  };

  const handleBlur = () => {
    if (money && value) {
      setValue(formatMoney(parseFloat(value)));
    }
  };

  return (
    <input
      type="text"
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default NumericInput;
