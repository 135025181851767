import logo from "../../assets/img/logo.png";

const Header = () => {
  return (
    <nav aria-label="Top">
      <div className="bg-white">
        <div className="border-b border-gray-200">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              {/* Logo (lg+) */}
              <div className="hidden lg:flex lg:items-center">
                <a href="/">
                  <span className="sr-only">La Divinata</span>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </a>
              </div>

              {/* Logo (lg-) */}
              <a href="/" className="lg:hidden">
                <span className="sr-only">La Divinata</span>
                <img src={logo} alt="" className="h-8 w-auto" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
