import ApiClient from "./ApiClient";

const diviApiClient = new ApiClient(process.env.REACT_APP_API_URL, {
  "Content-Type": "application/json",
});

const DiviApi = {
  // orders
  getOrder: (no_order) =>
    diviApiClient.get(`/find_orders?no_order=${no_order}`),
  // invoices
  getInvoice: (no_order) =>
    diviApiClient.get(`/find_invoices?no_order=${no_order}`),
  createInvoice: (params) => diviApiClient.post("/create_invoice", params),
};

export default DiviApi;
