import { footerNavigation } from "../../mocks/navigation/index";
import logo from "../../assets/img/logo.png";
import flag from "../../assets/img/mexico.png";

const Footer = () => {
  return (
    <footer aria-labelledby="footer-heading" className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="py-10 md:flex md:items-center md:justify-between">
          <div className="text-center md:text-left">
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} Derechos reservados
            </p>
          </div>

          <div className="mt-4 flex items-center justify-center md:mt-0">
            <div className="flex space-x-8">
              {footerNavigation.bottomLinks.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm text-gray-500 hover:text-gray-600"
                >
                  {item.name}
                </a>
              ))}
            </div>

            <div className="ml-6 border-l border-gray-200 pl-6">
              <a
                href="#"
                className="flex items-center text-gray-500 hover:text-gray-600"
              >
                <img src={flag} alt="" className="h-auto w-5 flex-shrink-0" />
                <span className="ml-3 text-sm">Cambiar</span>
                <span className="sr-only">location and currency</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
