import React from 'react';

const Card = ({ children, className }) => {
  return (
    <div className={`overflow-hidden rounded-lg shadow ${className || 'bg-white'}`}>
      <div className="p-2 sm:p-2">
        { children }
      </div>
    </div>
  )
}

export default Card;