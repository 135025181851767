import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layouts/Layout";
import "./App.css";
import { OrderProvider } from "./providers/app.provider";

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <OrderProvider>
        <Layout />
      </OrderProvider>
    </Router>
  );
};

export default App;
