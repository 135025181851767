import React, { useState } from 'react';

const CustomInput = ({ 
  format, 
  value, 
  setValue, 
  ...props 
}) => {

  const formatCustom = (value) => {
    // Delete chars not number
    const cleaned = value.replace(/\D/g, ''); 
    const match = cleaned.match(/^(\d{5})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    // Return value without changes if not valid format
    return value; 
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const handleBlur = () => {
    if (format && value) {
      setValue(formatCustom(value));
    }
  };

  return (
    <input
      {...props}
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default CustomInput;
