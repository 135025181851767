export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
export const allowOnlyNumbers = (value) => {
  // Allow numbers & only one dot
  return value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
};

export const getRegimes = () => {
  return [
    { key: 601, name: "General de Ley Personas Morales", pf: false, pm: true },
    {
      key: 603,
      name: "Personas Morales con Fines no Lucrativos",
      pf: false,
      pm: true,
    },
    {
      key: 605,
      name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      pf: true,
      pm: false,
    },
    { key: 606, name: "Arrendamiento", pf: true, pm: false },
    {
      key: 607,
      name: "Régimen de Enajenación o Adquisición de Bienes",
      pf: true,
      pm: false,
    },
    { key: 608, name: "Demás ingresos", pf: true, pm: false },
    {
      key: 610,
      name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
      pf: true,
      pm: true,
    },
    {
      key: 611,
      name: "Ingresos por Dividendos (socios y accionistas)",
      pf: true,
      pm: false,
    },
    {
      key: 612,
      name: "Personas Físicas con Actividades Empresariales y Profesionales",
      pf: true,
      pm: false,
    },
    { key: 614, name: "Ingresos por intereses", pf: true, pm: false },
    {
      key: 615,
      name: "Régimen de los ingresos por obtención de premios",
      pf: true,
      pm: false,
    },
    { key: 616, name: "Sin obligaciones fiscales", pf: true, pm: false },
    {
      key: 620,
      name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      pf: false,
      pm: true,
    },
    { key: 621, name: "Incorporación Fiscal", pf: true, pm: false },
    {
      key: 622,
      name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      pf: false,
      pm: true,
    },
    {
      key: 623,
      name: "Opcional para Grupos de Sociedades",
      pf: false,
      pm: true,
    },
    { key: 624, name: "Coordinados", pf: false, pm: true },
    {
      key: 625,
      name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      pf: true,
      pm: false,
    },
    { key: 626, name: "Régimen Simplificado de Confianza", pf: true, pm: true },
  ];
};

export const getCFDI = () => {
  return [
    { key: "G01", value: "Adquisición de mercancías" },
    { key: "G02", value: "Devoluciones, descuentos o bonificaciones" },
    { key: "G03", value: "Gastos en general" },
    { key: "I01", value: "Construcciones" },
    { key: "I02", value: "Mobiliario y equipo de oficina por inversiones" },
    { key: "I03", value: "Equipo de transporte" },
    { key: "I04", value: "Equipo de cómputo y accesorios" },
    { key: "I05", value: "Dados, troqueles, moldes, matrices y herramental" },
    { key: "I06", value: "Comunicaciones telefónicas" },
    { key: "I07", value: "Comunicaciones satelitales" },
    { key: "I08", value: "Otra maquinaria y equipo" },
    {
      key: "D01",
      value: "Honorarios médicos, dentales y gastos hospitalarios.",
    },
    { key: "D02", value: "Gastos médicos por incapacidad o discapacidad" },
    { key: "D03", value: "Gastos funerarios" },
    { key: "D04", value: "Donativos" },
    {
      key: "D05",
      value:
        "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
    },
    { key: "D06", value: "Aportaciones voluntarias al SAR" },
    { key: "D07", value: "Primas de seguros de gastos médicos" },
    { key: "D08", value: "Gastos de transportación escolar obligatoria" },
    {
      key: "D09",
      value:
        "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
    },
    { key: "D10", value: "Pagos por servicios educativos (colegiaturas)" },
    { key: "S01", value: "Sin efectos fiscales" },
  ];
};
