import React from 'react';
import Card from '../card/simple-card';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const ErrorAlert = ({ children }) => {
  return (
    <Card className="bg-red-100">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium text-red-400">
            { children }
          </p>
        </div>
      </div>
    </Card>
  );
}

export default ErrorAlert;