import axios from 'axios';

class ApiClient {
  constructor(baseURL, headers) {
    this.client = axios.create({
      baseURL,
      headers: headers,
    });
  }

  async request(endpoint, method = 'GET', data = null, headers = {}) {
    try {
      const response = await this.client.request({
        url: endpoint,
        method,
        data,
        headers,
      });
      return response.data;
    } catch (error) {
      console.error('API request error:', error);
      if (error.response) {
        throw new Error(`HTTP error! status: ${error.response.status}`);
      } else {
        throw new Error(`API request error: ${error.message}`);
      }
    }
  }

  get(endpoint, headers = {}) {
    return this.request(endpoint, 'GET', null, headers);
  }

  post(endpoint, data, headers = {}) {
    return this.request(endpoint, 'POST', data, headers);
  }

  put(endpoint, data, headers = {}) {
    return this.request(endpoint, 'PUT', data, headers);
  }

  delete(endpoint, headers = {}) {
    return this.request(endpoint, 'DELETE', null, headers);
  }
}

export default ApiClient;
