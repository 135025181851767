import { Fragment, useState } from "react";
import { Loader2 } from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { Popover, Transition } from "@headlessui/react";
import ticketExample from "../assets/img/ticket-example.jpeg";
import SuccessInputModal from "../components/dialog/SuccessInput";
import NumericInput from "../components/input/numeric.input";
import ErrorAlert from "../components/alert/error.alert";
import CustomInput from "../components/input/custom.input";
import DiviApi from "../api/diviApi";
import ModalError from "../components/modal/modal.error";
import { useOrder } from "../hooks/useOrder";

const InvoicesPage = () => {
  let navigate = useNavigate();
  const { order, setOrder } = useOrder();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [ticket, setTicket] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(0);
  const [rfcSuccessModal, setRfcSuccessModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "El total que ingresaste, no coincide con ticket del sistema. Revisa una vez mas el ticket & prueba de nuevo."
  );

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const convertFormatMoneyToDecimal = (value) => {
    const cleanString = value.toString().replace("$", "").replace(/,/g, "");
    return parseFloat(cleanString);
  };

  const compareAmounts = (a, b) => {
    const precision = 2;
    const factor = Math.pow(10, precision);
    const numberA =
      Math.round(convertFormatMoneyToDecimal(a) * factor) / factor;
    const numberB = Math.round(b * factor) / factor;
    return numberA === numberB;
  };

  const successCallback = (ord) => {
    setOrder(ord);
    if (compareAmounts(total, ord.amount_total)) {
      if (ord.is_invoiced) {
        setRfcSuccessModal(true);
      } else {
        navigate("/make-invoice");
      }
    } else {
      if (ord.error) setErrorMessage(ord.message);
      setOpenModalError(true);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (captchaToken && ticket && total) {
      setError(false);
      const response = await findOrder();
      successCallback(response);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const findOrder = async () => {
    const reponse = await DiviApi.getOrder(ticket);
    setLoading(false);
    return reponse;
  };

  return (
    <main className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
      <section
        aria-labelledby="summary-heading"
        className="bg-gray-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
      >
        <div className="mx-auto max-w-lg lg:max-w-none">
          <h2
            id="summary-heading"
            className="text-lg font-medium text-gray-900"
          >
            Ejemplo de ticket
          </h2>

          <dl className="space-y-6 border-t border-gray-200 pt-8 mt-4 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between">
              <img
                src={ticketExample}
                alt={"Ejemplo de ticket"}
                className="h-auto w-64 flex-none rounded-md object-cover object-center mx-auto px-auto"
              />
            </div>
          </dl>

          <Popover className="hidden fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden">
            <div className="relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6">
              <div className="mx-auto max-w-lg">
                <Popover.Button className="flex w-full items-center py-6 font-medium">
                  <span className="mr-auto text-base">Total</span>
                  <span className="mr-2 text-base">$361.80</span>
                  <ChevronUpIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>
            </div>

            <Transition.Root as={Fragment}>
              <div>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Popover.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Popover.Panel className="relative bg-white px-4 py-6 sm:px-6">
                    <dl className="mx-auto max-w-lg space-y-6">
                      <div className="flex items-center justify-between">
                        <dt className="text-gray-600">Subtotal</dt>
                        <dd>$320.00</dd>
                      </div>

                      <div className="flex items-center justify-between">
                        <dt className="text-gray-600">Shipping</dt>
                        <dd>$15.00</dd>
                      </div>

                      <div className="flex items-center justify-between">
                        <dt className="text-gray-600">Taxes</dt>
                        <dd>$26.80</dd>
                      </div>
                    </dl>
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </Transition.Root>
          </Popover>
        </div>
      </section>

      <div className="px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
        <div className="mx-auto max-w-lg lg:max-w-none">
          <section aria-labelledby="contact-info-heading">
            <h2
              id="contact-info-heading"
              className="text-lg font-medium text-gray-900"
            >
              Información del ticket
            </h2>
            <div className="mt-6">
              {error && !total && <ErrorAlert>Ingresa el total</ErrorAlert>}
              <label
                htmlFor="email-address"
                className="mt-2 block text-sm font-medium text-gray-700"
              >
                Total
              </label>
              <div className="mt-1">
                <NumericInput
                  id="ticket-total"
                  name="ticket-total"
                  value={total}
                  setValue={setTotal}
                  money
                  onChange={setTotal}
                  placeholder="Ingresa solo el número..."
                />
              </div>
            </div>
          </section>

          <section aria-labelledby="payment-heading" className="mt-10">
            <div className="mt-6 grid grid-cols-3 gap-x-4 gap-y-6 sm:grid-cols-4">
              <div className="col-span-3 sm:col-span-4">
                {error && !ticket && (
                  <ErrorAlert>Ingresa el número de orden</ErrorAlert>
                )}
                <label
                  htmlFor="name-on-card"
                  className={`${
                    error && "mt-2"
                  } block text-sm font-medium text-gray-700 `}
                >
                  Número de orden
                </label>
                <div className="mt-1">
                  <CustomInput
                    type="text"
                    id="name-on-card"
                    name="name-on-card"
                    autoComplete="cc-name"
                    placeholder="Ingresa el no. de orden..."
                    format
                    value={ticket}
                    setValue={setTicket}
                  />
                </div>
                <div className="mt-6">
                  {error && !captchaToken && (
                    <ErrorAlert>Confirma el captcha</ErrorAlert>
                  )}
                  <ReCAPTCHA
                    className={error ? `mt-2` : ""}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                </div>
              </div>
            </div>
          </section>

          <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
            <button
              onClick={handleSubmit}
              className={`w-auto flex text-center rounded-md border border-transparent ${
                loading
                  ? "cursor-not-allowed opacity-50 bg-indigo-700"
                  : "bg-indigo-600"
              } px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6`}
            >
              Buscar &nbsp;{" "}
              {loading && (
                <Loader2 className="mr-2 my-auto h-4 w-4 animate-spin" />
              )}
            </button>
            <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
              Con esta información podremos encontrar tu ticket.
            </p>
          </div>
        </div>
      </div>
      {order.id && (
        <SuccessInputModal
          order={order}
          open={rfcSuccessModal}
          setOpen={setRfcSuccessModal}
        />
      )}
      <ModalError
        title="Oops, algo no anda bien."
        message={errorMessage}
        open={openModalError}
        setOpen={setOpenModalError}
      />
    </main>
  );
};

export default InvoicesPage;
