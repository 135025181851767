import React, { useState } from "react";

const RFCInput = ({
  rfc, 
  setRFC
}) => {
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setRFC(formatRFC(value));
  };

  const handleBlur = () => {
    if (!validateRFC(rfc)) {
      setError("Invalid RFC. Please check the format.");
    } else {
      setError("");
    }
  };

  // Function to validate RFC format
  const validateRFC = (rfc) => {
    const re = /^([A-ZÑ&]{3,4})\d{6}([A-Z\d]{3})?$/;
    return re.test(rfc);
  };

  // Function to format the RFC
  const formatRFC = (rfc) => rfc.toUpperCase();

  return (
    <div>
      <input
        type="text"
        id="rfc"
        value={rfc}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={13}
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder="Ingresa tu RFC..."
      />
      {error && <p className="text-red-400 font-semibold mt-2 text-left">{error}</p>}
    </div>
  );
};

export default RFCInput;
