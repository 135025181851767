import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { currencies, headerNaviagtion } from "../mocks/navigation/index";
import Header from "../components/header";
import Footer from "../components/footer";
import MenuMobile from "../components/menu/MenuMobile";
import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";
import InvoicesPage from "../pages/InvoicesPage";
import MakeInvoicePage from "../pages/MakeInvoicePage";

const Layout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      <MenuMobile
        currencies={currencies}
        headerNaviagtion={headerNaviagtion}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      <header className="relative z-10">
        <Header
          currencies={currencies}
          headerNaviagtion={headerNaviagtion}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      </header>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/invoices" element={<InvoicesPage />} />
        <Route path="/make-invoice" element={<MakeInvoicePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <Footer />
    </div>
  );
};

export default Layout;
